<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Consentimentos
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          Últimos consentimentos realizados
        </span>
      </h3>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <router-link
              class="nav-link py-2 px-4 active"
              data-toggle="tab"
              :to="`/sites/${$route.params.id}/consents`"
            >
              Ver todos
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <TableConsents :consentsList="list" />
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import TableConsents from "@/view/content/widgets/table-consents.vue";
// import modalAccount from "../modal/Index";

export default {
  name: "consents",
  data() {
    return {
      show: "day",
      list: [],
      consent: {},
      type: {
        all: {
          name: "Todos",
          style: "success"
        },
        custom: {
          name: "Personalizado",
          style: "warning"
        },
        optout: {
          name: "Optout",
          style: "danger"
        }
      }
    };
  },
  components: {
    TableConsents
  },
  mounted() {
    this.getConsents();
  },
  methods: {
    getConsents() {
      ApiService.post(
        `visitorConsents/all/${this.$route.params.id}?page=1&limit=6`,
        {}
      )
        .then(({ data }) => {
          this.list = data.docs;
        })
        .catch(() => {
          //console.log("DATA ERROR", response);
        });
    }
  }
};
</script>
