<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-md-4">
        <stats :configs="consents"></stats>
      </div>
      <div class="col-xl-4 col-md-4">
        <stats :configs="accepted"></stats>
      </div>
      <div class="col-xl-4 col-md-4">
        <stats :configs="custom"></stats>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <consents></consents>
      </div>
      <!-- <div class="col-lg-4">
        <Statistics :stats="account.stats"></Statistics>
      </div> -->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <template v-if="account._id">
          <GraphEvolution :account="account._id"></GraphEvolution>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import consents from "./comps/consents";
import GraphEvolution from "@/view/content/widgets/GraphEvolution";
import stats from "@/view/content/widgets/stats.vue";

export default {
  name: "site-detail",

  props: {
    account: { type: Object, required: true }
  },

  components: {
    consents,
    stats,
    GraphEvolution
  },

  data() {
    return {
      selected: ""
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile Detail" }]);
  },

  computed: {
    consents() {
      return {
        style: "info",
        title: "Consentimentos",
        description: "Total de consentimentos",
        subtitle: "Total de visualizações",
        total: this.account?.stats?.total,
        subtotal: this.account?.stats?.views
      };
    },
    accepted() {
      return {
        style: "success",
        title: "Aceitação",
        description: "Pessoas que aceitaram todos os cookies",
        subtitle: "Total de consentimentos",
        total: this.account?.stats?.all,
        subtotal: this.account?.stats?.consents
      };
    },
    custom() {
      return {
        style: "warning",
        title: "Personalizados",
        description: "Clientes que customizaram os cookies",
        subtitle: "Total de consentimentos",
        total: this.account?.stats?.custom,
        subtotal: this.account?.stats?.consents
      };
    }
  }
};
</script>
